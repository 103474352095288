@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import "helpers";

@mixin filterStyle() {
  background-size: 65% !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.filter-button.agrotec-sk-iveco {
  background: url("../img/catalog/iveco.png");
  @include filterStyle();
}

.filter-button.agrotec-sk-fiat  {
  background: url("../img/catalog/fiat.png");
  @include filterStyle();
}

.filter-button.agrotec-sk-jeep {
  background: url("../img/catalog/jeep.png");
  @include filterStyle();
}

.filter-button.agrotec-sk-used-cars {
  background: url("../img/catalog/logo-ojete.png");
  @include filterStyle();
}

.filter-button.agrotec-sk-used-trucks {
  background: url("../img/catalog/proverene-ojete-vozy.png");
  @include filterStyle();
}
